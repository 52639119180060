/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl, KTIcon} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'

const MyProfilePage: FC = () => (
  <>

  </>
)

const MyProfileWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>My Profile</PageTitle>
      <MyProfilePage />
    </>
  )
}

export {MyProfileWrapper}
