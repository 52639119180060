import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/DashboardWrapper'
import {MyProfileWrapper} from '../pages/MyProfileWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  // const UsersPage1 = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const UsersPage = lazy(() => import('../modules/manage/users/UsersPage'))
  const RolesPermissionsPage = lazy(() => import('../modules/manage/roles-permissions/RolesPermissionsPage'))
  const LocationsPage = lazy(() => import('../modules/manage/locations/MainPage'))
  const CustomersPage = lazy(() => import('../modules/customer/customers/MainPage'))
  const MatchPointsPage = lazy(() => import('../modules/customer/matchpoints/MainPage'))
  const TicketOutPage = lazy(() => import('../modules/customer/ticketouts/MainPage'))
  const RafflePage = lazy(() => import('../modules/customer/promotions/raffle/MainPage'))
  const DrawingsPage = lazy(() => import('../modules/customer/promotions/drawings/MainPage'))
  const BankPage = lazy(() => import('../modules/accounts/deposits/bank/MainPage'))
  const PullPage = lazy(() => import('../modules/accounts/deposits/pull/MainPage'))
  const ExpensesPage = lazy(() => import('../modules/accounts/expenses/MainPage'))
  const BonusPage =  lazy(() => import('../modules/customer/promotions/bonus/MainPage'))
  const MachineReadingsPage =  lazy(() => import('../modules/accounts/machine-readings/MainPage'))
  const MachinesPage =  lazy(() => import('../modules/settings/machines/MainPage'))
  const ExpenseTypesPage =  lazy(() => import('../modules/settings/expense-types/MainPage'))
  const OtherSettingsPage =  lazy(() => import('../modules/settings/other-settings/MainPage'))
  const ReportsPLPage =  lazy(() => import('../modules/reports/pl/MainPage'))
  const ReportsEmpShiftSummaryPage = lazy(() => import('../modules/reports/emp-shift-summary/MainPage'))
  const ReportsMatchPoints = lazy(() => import('../modules/reports/match-points/MainPage'))
  const ReportsTicketoutsBonuses = lazy(() => import('../modules/reports/ticketouts-bonuses/MainPage'))
  const EmployeeShiftsPage = lazy(() => import('../modules/settings/shifts/MainPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='myprofile' element={<MyProfileWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='manage/users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='manage/users/roles-permissions/*'
          element={
            <SuspensedView>
              <RolesPermissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='manage/locations/*'
          element={
            <SuspensedView>
              <LocationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/machines/*'
          element={
            <SuspensedView>
              <MachinesPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/expense-types/*'
          element={
            <SuspensedView>
              <ExpenseTypesPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/other/*'
          element={
            <SuspensedView>
              <OtherSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='customers/*'
          element={
            <SuspensedView>
              <CustomersPage />
            </SuspensedView>
          }
        />
        <Route
          path='match-points/*'
          element={
            <SuspensedView>
              <MatchPointsPage />
            </SuspensedView>
          }
        />
        <Route
          path='ticket-outs/*'
          element={
            <SuspensedView>
              <TicketOutPage />
            </SuspensedView>
          }
        />
        <Route
          path='promotions/raffle/*'
          element={
            <SuspensedView>
              <RafflePage />
            </SuspensedView>
          }
        />
        <Route
          path='promotions/drawings/*'
          element={
            <SuspensedView>
              <DrawingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='promotions/bonus/*'
          element={
            <SuspensedView>
              <BonusPage />
            </SuspensedView>
          }
        />
        <Route
          path='deposits/bank/*'
          element={
            <SuspensedView>
              <BankPage />
            </SuspensedView>
          }
        />
        <Route
          path='deposits/pull/*'
          element={
            <SuspensedView>
              <PullPage />
            </SuspensedView>
          }
        />
        <Route
          path='expenses/*'
          element={
            <SuspensedView>
              <ExpensesPage />
            </SuspensedView>
          }
        />
        <Route
          path='machine-readings/*'
          element={
            <SuspensedView>
              <MachineReadingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/profit-loss'
          element={
            <SuspensedView>
              <ReportsPLPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/emp-shift-summary'
          element={
            <SuspensedView>
              <ReportsEmpShiftSummaryPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/match-points'
          element={
            <SuspensedView>
              <ReportsMatchPoints />
            </SuspensedView>
          }
        />
        <Route
          path='reports/ticketouts-promotions'
          element={
            <SuspensedView>
              <ReportsTicketoutsBonuses />
            </SuspensedView>
          }
        />
        <Route
          path='settings/shifts/*'
          element={
            <SuspensedView>
              <EmployeeShiftsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
