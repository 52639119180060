/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, checkHasPermission} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='/manage/users'
        title='User Management'
        icon='profile-circle'
        fontIcon='bi-person'
        checkPermission='view_users|view_permissions|view_roles'
      >
        <SidebarMenuItem to='/manage/users/list' title='Users' hasBullet={true} checkPermission='view_users' />
        <SidebarMenuItem to='/manage/users/roles-permissions/list' title='Roles & Permissions' hasBullet={true} checkPermission='view_permissions|view_roles' onlyForSuper={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/manage/locations/list'
        icon='geolocation-home'
        title='Locations'
        fontIcon='bi-geo-alt'
        checkPermission='view_locations'
      />
      <SidebarMenuItemWithSub
        to='/settings'
        title='Settings'
        icon='profile-circle'
        fontIcon='bi-gear'
        checkPermission='manage_location_settings'
      >
        <SidebarMenuItem to='/settings/machines/list' title='Machines' hasBullet={true} checkPermission='view_machines' />
        <SidebarMenuItem to='/settings/expense-types/list' title='Expense Types' hasBullet={true} checkPermission='manage_location_settings' />
        <SidebarMenuItem to='/settings/other' title='Other Settings' hasBullet={true} checkPermission='manage_location_settings' />
        <SidebarMenuItem to='/settings/shifts/list' title='Shifts' hasBullet={true} checkPermission='view_employee_shift' />
      </SidebarMenuItemWithSub>
      {checkHasPermission('view_customers') && (
      <div className='menu-item'>
        <div className='menu-content pt-3 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Customer</span>
        </div>
      </div>)}
      <SidebarMenuItem
        to='/customers/list'
        icon='geolocation-home'
        title='Manage Customers'
        fontIcon='bi-people'
        checkPermission='view_customers'
      />
      <SidebarMenuItem
        to='/match-points/list'
        icon='geolocation-home'
        title='Match Points'
        fontIcon='bi-coin'
        checkPermission='view_match_points'
      />
      <SidebarMenuItem
        to='/ticket-outs/list'
        icon='geolocation-home'
        title='Ticket Out'
        fontIcon='bi-ticket-perforated'
        checkPermission='view_ticket_outs'
      />
      <SidebarMenuItemWithSub
        to='/promotions'
        title='Promotions'
        icon='profile-circle'
        fontIcon='bi-trophy'
        checkPermission='view_raffle|view_drawings'
      >
        <SidebarMenuItem to='/promotions/raffle/list' title='Raffle' hasBullet={true} checkPermission='view_raffle' />
        <SidebarMenuItem to='/promotions/drawings/list' title='Drawings' hasBullet={true} checkPermission='view_drawings' />
        <SidebarMenuItem to='/promotions/bonus/list' title='Bonus' hasBullet={true} checkPermission='view_bonus' />
      </SidebarMenuItemWithSub>
      
      {checkHasPermission('view_accounts') && (
      <div className='menu-item'>
        <div className='menu-content pt-3 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Account</span>
        </div>
      </div>)}
      <SidebarMenuItemWithSub
        to='/deposits'
        title='Deposits'
        icon='profile-circle'
        fontIcon='bi-safe'
        checkPermission='view_bank|view_pull'
      >
        <SidebarMenuItem to='/deposits/bank/list' title='Bank' hasBullet={true} checkPermission='view_bank' />
        <SidebarMenuItem to='/deposits/pull/list' title='Pull' hasBullet={true} checkPermission='view_pull' />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/expenses/list'
        icon='geolocation-home'
        title='Expenses'
        fontIcon='bi-wallet2'
        checkPermission='view_expenses'
      />
      {/* <SidebarMenuItem
        to='/account/bonus'
        icon='geolocation-home'
        title='Bonus'
        fontIcon='bi-geo-alt'
        checkPermission='view_bonus'
      /> */}
      <SidebarMenuItem
        to='/machine-readings/list'
        icon='geolocation-home'
        title='Machine Readings'
        fontIcon='bi-card-text'
        checkPermission='view_machine_readings'
      />
      {checkHasPermission('view_reports') && (
      <div className='menu-item'>
        <div className='menu-content pt-3 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Reports</span>
        </div>
      </div>)}
      <SidebarMenuItem
        to='/reports/profit-loss'
        icon='geolocation-home'
        title='P&L Statement'
        fontIcon='bi-file-bar-graph'
        checkPermission='view_pl_report'
      />
      <SidebarMenuItem
        to='/reports/emp-shift-summary'
        icon='geolocation-home'
        title='Employee Shift Summary'
        fontIcon='bi-file-bar-graph'
        checkPermission='view_employee_shift_summary_report|view_all_employee_shift_summary_report'
      />
      <SidebarMenuItem
        to='/reports/match-points'
        icon='geolocation-home'
        title='Match Points Report'
        fontIcon='bi-file-bar-graph'
        checkPermission='view_match_points_report'
      />
      <SidebarMenuItem
        to='/reports/ticketouts-promotions'
        icon='geolocation-home'
        title='Ticketouts & Promotions Report'
        fontIcon='bi-file-bar-graph'
        checkPermission='view_ticketouts_bonuses_report'
      />
      
      <div className='menu-item d-none'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}

export {SidebarMenuMain}
