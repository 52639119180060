import axios, {AxiosResponse, AxiosError} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL

export const REPORTS_URL = `${API_URL}/reports/profit-loss`
export const DASHBOARD_URL = `${API_URL}/reports/dashboard-report`

const getReport = (startDate: string,endDate: string): Promise<any> => {
  return axios
    .get(`${REPORTS_URL}?start_date=${startDate}&end_date=${endDate}`)
    .then((response: Response<any>) => response.data)
}

const getDashboardReport = (): Promise<any> => {
  return axios
    .get(`${DASHBOARD_URL}`)
    .then((response: Response<any>) => response.data)
}


export {getReport, getDashboardReport}
