import axios, {AxiosResponse, AxiosError} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Location, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL

export const LOCATIONS_SETTINGS_URL = `${API_URL}/locations/settings`

const getSettings = (): Promise<Location | undefined> => {
  return axios
    .get(`${LOCATIONS_SETTINGS_URL}`)
    .then((response: Response<Location>) => response.data)
}
const updateSettings = (record: Location): Promise<Location | any> => {
  return axios
    .patch(`${LOCATIONS_SETTINGS_URL}`, record)
    .then((response: AxiosResponse<Response<Location>>) => response)
    .catch((reason: AxiosError<any>) => reason?.response)
}


export {getSettings, updateSettings}
